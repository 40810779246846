import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import { DragDrop } from "@uppy/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileAlt, FaSignature } from "react-icons/fa";
import {
  ContractDto,
  SignedDocumentDto,
  TextDocumentDto,
  useCreateContractTextDocumentMutation,
  useCreateSignedDocumentMutation,
  useDeleteTextDocumentMutation,
  useListTextDocumentsForContractQuery,
  useUpdateDocumentNameMutation,
  useUpdateTextDocumentMutation,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { FileRow, FileUpload } from "../../../../common/documents/UppyUploader/FileRow";
import { useUppyUploader } from "../../../../common/documents/UppyUploader/useUppyUploader";
import { useApiError } from "../../../../common/errors/useApiError";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { generateHTMLFromTextDocument } from "../../../../common/input/TipTap/downloadTextDocument";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadContractThunk } from "../../../../common/redux/thunks/contract/load-contract-thunk";
import { Explanation } from "../../../../common/support/Explanation";
import { requireDocumentFields, requireEditingMetaFields } from "../../typeguards";
import { useContractState } from "../useContractState";
import "./Docs.scss";
import { DocumentSignersModal } from "./signatures/DocumentSignersModal";
import { SignerInput } from "./signatures/InvitedSigner";
import { SendForSigningModal } from "./signatures/SendForSigningModal";
import { SignedDocumentRow } from "./signatures/SignedDocumentRow";
interface Props {
  previousStep: () => void;
  nextStep: () => void;
}

export const Docs = ({ previousStep, nextStep }: Props) => {
  const [showSigningModal, setShowSigningModal] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const contractState = useContractState();
  const documentFields = requireDocumentFields(contractState.contract as ContractDto);
  const signableDocuments = useMemo(() => {
    return documentFields.documents.filter((d) => d.fileExtension === ".pdf");
  }, [documentFields.documents]);
  const editingMetaFields = requireEditingMetaFields(contractState.contract as ContractDto);
  const [createSignedDocument] = useCreateSignedDocumentMutation();
  const [updateDocumentName] = useUpdateDocumentNameMutation();
  const [createTextDocument, { isLoading: isCreatingTextDocument }] = useCreateContractTextDocumentMutation();
  const [updateTextDocument, { isLoading: isUpdatingTextDocument }] = useUpdateTextDocumentMutation();
  const [deleteTextDocument] = useDeleteTextDocumentMutation();
  const { data: textDocumentRes, isLoading: isLoadingTextDocuments } = useListTextDocumentsForContractQuery({
    contractId: contractState.id,
  });
  const toast = useToast();
  const displayer = useApiError();

  const loadContract = useCallback(() => {
    dispatch(loadContractThunk({ contractId: contractState.id }));
  }, [contractState.id, dispatch]);

  const { uppy, allFiles, setDocuments, setUploads, removeUpload } = useUppyUploader({
    entityId: contractState.id,
    entityType: "Contract",
    reloadEntity: loadContract,
  });

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();
  const [signedDocumentToShow, setSignedDocumentToShow] = useState<SignedDocumentDto>();

  useEffect(() => {
    setUploads(editingMetaFields.uploads);
  }, [editingMetaFields.uploads, setUploads]);

  useEffect(() => {
    setDocuments(documentFields.documents);
  }, [documentFields.documents, setDocuments]);

  const sendForSigning = async (values: {
    contractDocument: SelectorValue;
    attachments?: SelectorValue[];
    signers?: SignerInput[];
    deadline?: string;
  }): Promise<boolean> => {
    if (!values.signers) throw new Error("Signers are not defined");

    const mainDocumentId = signableDocuments.find((s) => s.id === values.contractDocument.value)?.id;
    const textDocument = textDocumentRes?.documents.find((d) => d.id === values.contractDocument.value);
    const documentAttachments = signableDocuments?.filter((s) =>
      values.attachments?.map((a) => a.value).includes(s.id)
    );
    const textDocumentAttachments = textDocumentRes?.documents?.filter((d) =>
      values.attachments?.map((a) => a.value).includes(d.id)
    );

    const result = await createSignedDocument({
      contractId: contractState.id,
      createSignedDocumentRequest: {
        mainDocumentId,
        textDocumentInput: textDocument
          ? {
              textDocumentId: textDocument.id,
              htmlContent: generateHTMLFromTextDocument(textDocument.text),
            }
          : undefined,
        attachments: documentAttachments?.map((a) => a.id),
        textDocumentAttachments: textDocumentAttachments?.map((d) => ({
          textDocumentId: d.id,
          htmlContent: generateHTMLFromTextDocument(d.text),
        })),
        signers: values.signers,
        language: "No",
        deadline: values.deadline,
      },
    });
    if ("data" in result) {
      toast({
        title: t("Contract sent for signing"),
        status: "success",
      });
      await dispatch(
        loadContractThunk({
          contractId: contractState.id,
        })
      );
      setShowSigningModal(false);
      return true;
    } else {
      displayer.trigger(result.error);
      return false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdateName = useCallback(
    debounce(({ docId, name }: { docId: string; name: string }) => {
      updateDocumentName({
        docId,
        updateDocumentNameRequest: { name },
      });
    }, 300),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateTextDocument({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  const allDocuments = [...allFiles, ...documentFields.signedDocuments];

  return (
    <>
      {signedDocumentToShow && (
        <DocumentSignersModal
          contractId={contractState.id}
          signedDocument={signedDocumentToShow}
          onClose={() => setSignedDocumentToShow(undefined)}
        />
      )}
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingTextDocument}
        />
      )}
      {showSigningModal && (
        <SendForSigningModal
          contractId={contractState.id}
          textDocuments={textDocumentRes?.documents}
          documents={signableDocuments}
          sendForSigning={sendForSigning}
          onClose={() => setShowSigningModal(false)}
        />
      )}
      <FormControl pt={8} pb="5">
        <FormLabel>{t("Upload documents")}</FormLabel>
        <Box id="dnd">
          <DragDrop uppy={uppy} style={{ backgroundColor: "transparent" }} />
        </Box>
        <FormHelperText>{t("Upload your contract documents")}</FormHelperText>
      </FormControl>
      <Box borderRadius={10} width="full" border="1px solid" borderColor={"smBorder"} pb="2">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t("Name")}</Th>
              <Th colSpan={4}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {allDocuments.map((d) => {
              if (isFileUpload(d)) {
                return (
                  <FileRow
                    key={d.uploadId}
                    file={d}
                    name={d.name}
                    remove={removeUpload}
                    onUpdateName={
                      d.status === "UploadCompleted"
                        ? async (name: string) => {
                            debouncedOnUpdateName({ docId: d.documentId, name });
                            setDocuments((docs) => {
                              const i = docs.findIndex((doc) => doc.id === d.documentId);
                              const updatedDoc = { ...docs[i], name };
                              const updatedDocs = [...docs];
                              updatedDocs[i] = updatedDoc;
                              return updatedDocs;
                            });
                          }
                        : undefined
                    }
                  />
                );
              }
              return (
                <SignedDocumentRow
                  name={d.name}
                  key={d.id}
                  document={d}
                  setSignedDocumentToShow={setSignedDocumentToShow}
                />
              );
            })}
            {!allFiles?.length && (
              <Tr>
                <Td colSpan={5} width="full" textAlign="center" fontSize="small">
                  {t("No documents")}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      {contractState.contract.owningOrgHasSignatureAccess && contractState.contract.state === "Published" && (
        <Flex justifyContent={"right"} pt="4">
          <Explanation
            fontSize="sm"
            enabled={signableDocuments.length === 0}
            text={t("Upload at least one PDF document in order to sign")}
          >
            <Button
              leftIcon={<Icon as={FaSignature} w="15px" h="15px" />}
              variant={"solid"}
              mr="5px"
              isDisabled={signableDocuments.length === 0}
              colorScheme={"teal"}
              size="sm"
              onClick={() => setShowSigningModal(true)}
            >
              {t("Document signing")}
            </Button>
          </Explanation>
        </Flex>
      )}
      <FormControl pt={4}>
        <FormLabel>{t("Create text document")}</FormLabel>
        <Flex flexDirection="column" alignItems="start">
          <Button
            colorScheme="teal"
            rightIcon={<Icon as={FaFileAlt} />}
            isLoading={isCreatingTextDocument}
            onClick={async () => {
              const res = await createTextDocument({
                createContractTextDocumentRequest: {
                  name: "",
                  text: "",
                  contractId: contractState.id,
                  tags: [],
                },
              });
              if ("error" in res) displayer.show(res.error);
              else setTextDocumentToEdit(res.data);
            }}
          >
            {t("Create")}
          </Button>
          <FormHelperText>{t("Create a new document from scratch or based on templates")}.</FormHelperText>
        </Flex>
      </FormControl>
      <FormControl pt={8}>
        <FormLabel>{t("Text documents")}</FormLabel>
        <TextDocumentTable
          isLoading={isLoadingTextDocuments}
          documents={textDocumentRes?.documents}
          onClick={setTextDocumentToEdit}
          onDelete={async (id: string) => {
            await deleteTextDocument({ id });
          }}
        />
      </FormControl>
      <Flex pt="8" pb="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};

const isFileUpload = (e: unknown): e is FileUpload => {
  return (e as FileUpload).uploadId !== undefined;
};
