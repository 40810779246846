import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Errors = () => {
  const { t } = useTranslation();
  return (
    <Box display={"none"}>
      {t("zz_BidApiErrorCode_Unknown")}
      {t("zz_BidApiErrorCode_InvalidBidId")}
      {t("zz_BidApiErrorCode_InvalidSourcingEventId")}
      {t("zz_BidApiErrorCode_InvalidOrganizationId")}
      {t("zz_BidApiErrorCode_InvalidDepartmentId")}
      {t("zz_BidApiErrorCode_BidDraftAlreadyCreated")}
      {t("zz_BidApiErrorCode_CannotCreateDraftBeforeInviteIsAccepted")}
      {t("zz_BidApiErrorCode_CanOnlyDeleteBidsInDraftMode")}
      {t("zz_BidApiErrorCode_BidAlreadyDelivered")}
      {t("zz_BidApiErrorCode_CommentRequiredBeforeDelivery")}
      {t("zz_BidApiErrorCode_CannotDeliverBidWhenDeadlineHasPassed")}
      {t("zz_BidApiErrorCode_EventNotFound")}
      {t("zz_BidApiErrorCode_BidHasAlreadyBeenDeliveredForYourOrg")}
      {t("zz_BidApiErrorCode_CannotChangeCommentForDeliveredBid")}
      {t("zz_BidApiErrorCode_InvalidDocumentId")}
      {t("zz_BidApiErrorCode_CannotUnDeliverBidThatIsNotDelivered")}
      {t("zz_BidApiErrorCode_CannotUnDeliverBidBecauseDeadlineHasPassed")}
      {t("zz_BidApiErrorCode_CannotUnDeliverBidBecauseEventHasBeenAwarded")}
      {t("zz_BidApiErrorCode_CannotUndeliverFinalBid")}
      {t("zz_BidApiErrorCode_CannotChangeDocumentsForDeliveredBid")}
      {t("zz_BidApiErrorCode_CannotChangeProductsForDeliveredBid")}
      {t("zz_BidApiErrorCode_BidNotFound")}
      {t("zz_BidApiErrorCode_CannotAddContractBeforeDelivery")}
      {t("zz_ContractApiErrorCode_Unknown")}
      {t("zz_ContractApiErrorCode_ContractNotFound")}
      {t("zz_ContractApiErrorCode_ContractDocumentNotFound")}
      {t("zz_ContractApiErrorCode_DocumentToRemoveNotFound")}
      {t("zz_ContractApiErrorCode_MainDocumentMustBePdf")}
      {t("zz_ContractApiErrorCode_DataFieldNotFound")}
      {t("zz_ContractApiErrorCode_TemplateNotFound")}
      {t("zz_ContractApiErrorCode_TaskNotFound")}
      {t("zz_ContractApiErrorCode_TemplateNameCannotBeEmpty")}
      {t("zz_ContractApiErrorCode_CouldNotFindNewOrganization")}
      {t("zz_ContractApiErrorCode_CouldNotFindNewPerson")}
      {t("zz_ContractApiErrorCode_CouldNotFindOldOrganization")}
      {t("zz_ContractApiErrorCode_CouldNotFindOldPerson")}
      {t("zz_ContractApiErrorCode_ParticipantNotFound")}
      {t("zz_ContractApiErrorCode_DataFieldNotAdded")}
      {t("zz_ContractApiErrorCode_CounterpartyOrganizationEntryNotFound")}
      {t("zz_ContractApiErrorCode_CounterpartyOrganizationNotFound")}
      {t("zz_ContractApiErrorCode_IndustryNotFound")}
      {t("zz_ContractApiErrorCode_LinkedContractsIsSameAsContractBeingUpdated")}
      {t("zz_ContractApiErrorCode_LinkedContractsNotFound")}
      {t("zz_ContractApiErrorCode_NotificationReceiverNotFound")}
      {t("zz_ContractApiErrorCode_OriginSourcingEventNotFound")}
      {t("zz_ContractApiErrorCode_OwningOrganizationCanNotBeCounterparty")}
      {t("zz_ContractApiErrorCode_InternalOwnerNotFound")}
      {t("zz_ContractApiErrorCode_OrganizationNotFound")}
      {t("zz_ContractApiErrorCode_PersonNotFound")}
      {t("zz_ContractApiErrorCode_InvalidResponsibleOrg")}
      {t("zz_ContractApiErrorCode_InvalidResponsiblePerson")}
      {t("zz_ContractApiErrorCode_CanNotAddOwnerAsParticipant")}
      {t("zz_ContractApiErrorCode_TooManyNotifications")}
      {t("zz_ContractApiErrorCode_CanOnlyUpdateNotificationsInPublishedState")}
      {t("zz_ContractApiErrorCode_CanOnlyUpdateParticipantsInPublishedState")}
      {t("zz_ContractApiErrorCode_CanOnlyUpdateTasksInPublishedState")}
      {t("zz_ContractApiErrorCode_CannotBothHaveExpirationDateAndNoExpirationDate")}
      {t("zz_ContractApiErrorCode_CannotGetTextOfDeletedComment")}
      {t("zz_ContractApiErrorCode_CannotPublishWithoutCounterparty")}
      {t("zz_ContractApiErrorCode_CommentNotFound")}
      {t("zz_ContractApiErrorCode_ContractDoesNotHaveTemplate")}
      {t("zz_ContractApiErrorCode_ContractIsAlreadyPublished")}
      {t("zz_ContractApiErrorCode_InvalidAmountInNotificationConfig")}
      {t("zz_ContractApiErrorCode_InvalidTemplateName")}
      {t("zz_ContractApiErrorCode_NewOrganizationDoesNotMatch")}
      {t("zz_ContractApiErrorCode_NewPersonDoesNotMatch")}
      {t("zz_ContractApiErrorCode_NotificationHasAlreadyBeenSentForThisDate")}
      {t("zz_ContractApiErrorCode_NotificationNotFound")}
      {t("zz_ContractApiErrorCode_OldOrganizationDoesNotMatch")}
      {t("zz_ContractApiErrorCode_OldPersonDoesNotMatch")}
      {t("zz_ContractApiErrorCode_SignatureAlreadyProvidedForSigner")}
      {t("zz_ContractApiErrorCode_SignedDocumentNotFound")}
      {t("zz_ContractApiErrorCode_TemplateDoesNotMatchContract")}
      {t("zz_ContractApiErrorCode_TooManyDaysForNotificationConfig")}
      {t("zz_ContractApiErrorCode_TooManyMonthsForNotificationConfig")}
      {t("zz_ContractApiErrorCode_InvalidResponsibleParty")}
      {t("zz_ContractApiErrorCode_TerminationDateAlreadySet")}
      {t("zz_ContractApiErrorCode_AllReceiversAreNotParticipants")}
      {t("zz_ContractApiErrorCode_DepartmentNotFound")}
      {t("zz_ContractApiErrorCode_CouldNotUpdateContractPermission")}
      {t("zz_ContractApiErrorCode_OptionAlreadyExecuted")}
      {t("zz_ContractApiErrorCode_OptionNotFound")}
      {t("zz_ContractApiErrorCode_AttachmentNotFound")}
      {t("zz_ContractApiErrorCode_NoMainDocumentProvided")}
      {t("zz_DocumentApiErrorCode_InvalidDocumentId")}
      {t("zz_DocumentApiErrorCode_DocumentNotFound")}
      {t("zz_DocumentApiErrorCode_Unknown")}
      {t("zz_DocumentApiErrorCode_AttachmentNotFound")}
      {t("zz_DocumentApiErrorCode_DocumentAnalysisFailed")}
      {t("zz_EmailInviteApiErrorCode_Unknown")}
      {t("zz_EmailInviteApiErrorCode_InvalidInviteId")}
      {t("zz_EmailInviteApiErrorCode_CannotAcceptAlreadyAcceptedInvite")}
      {t("zz_EmailInviteApiErrorCode_InvalidEmail")}
      {t("zz_EmailInviteApiErrorCode_InvalidOrganizationId")}
      {t("zz_EmailInviteApiErrorCode_CannotDeleteAlreadyAcceptedInvite")}
      {t("zz_EmailInviteApiErrorCode_SecretNotMatching")}
      {t("zz_EmailInviteApiErrorCode_CannotSendReminderForUnPublishedSourcingEvent")}
      {t("zz_EmailInviteApiErrorCode_OrgUserEmailInviteAlreadyAccepted")}
      {t("zz_EmailInviteApiErrorCode_SourcingEventEmailInviteAlreadyAccepted")}
      {t("zz_EmailInviteApiErrorCode_SupplierEmailInviteAlreadyAccepted")}
      {t("zz_OrganizationApiErrorCode_Unknown")}
      {t("zz_OrganizationApiErrorCode_InvalidAdminEmails")}
      {t("zz_OrganizationApiErrorCode_InvalidOrganizationId")}
      {t("zz_OrganizationApiErrorCode_InvalidEmailInviteId")}
      {t("zz_OrganizationApiErrorCode_InvalidCountryId")}
      {t("zz_OrganizationApiErrorCode_InvalidIndustryIds")}
      {t("zz_OrganizationApiErrorCode_AboutUsTooShort")}
      {t("zz_OrganizationApiErrorCode_OrgNameTooShort")}
      {t("zz_OrganizationApiErrorCode_SpecializationsTooShort")}
      {t("zz_OrganizationApiErrorCode_CannotCreateOrganizationWithAlreadyAcceptedInvite")}
      {t("zz_OrganizationApiErrorCode_InvalidSourcingEventId")}
      {t("zz_OrganizationApiErrorCode_EmailInviteSecretNotMatching")}
      {t("zz_OrganizationApiErrorCode_NotAllowedToCreateOrganization")}
      {t("zz_OrganizationApiErrorCode_CannotCreateOrgWhenAlreadyOrganizationOwner")}
      {t("zz_OrganizationApiErrorCode_CannotChangeRoleForOrgOwner")}
      {t("zz_OrganizationApiErrorCode_CannotChangeRoleToOwner")}
      {t("zz_OrganizationApiErrorCode_ExistingPersonNotFound")}
      {t("zz_OrganizationApiErrorCode_InvalidPersonId")}
      {t("zz_OrganizationApiErrorCode_AlreadyLinked")}
      {t("zz_OrganizationApiErrorCode_EntryNotFound")}
      {t("zz_OrganizationApiErrorCode_OrganizationNotFound")}
      {t("zz_OrganizationApiErrorCode_NotAllowedToGenerateToken")}
      {t("zz_OrganizationApiErrorCode_OrgNumberAlreadyUsed")}
      {t("zz_OrganizationApiErrorCode_OrgNameAlreadyUsed")}
      {t("zz_OrganizationApiErrorCode_InvalidSearchParams")}
      {t("zz_OrganizationApiErrorCode_ServicePrincipalsHaveNoOrgContext")}
      {t("zz_OrganizationApiErrorCode_Forbidden")}
      {t("zz_PersonApiErrorCode_Unknown")}
      {t("zz_PersonApiErrorCode_PersonNotFound")}
      {t("zz_PersonApiErrorCode_DuplicateEmail")}
      {t("zz_PersonApiErrorCode_Forbidden")}
      {t("zz_PersonApiErrorCode_NoOrgContextForAzureUser")}
      {t("zz_SourcingEventApiErrorCode_Unknown")}
      {t("zz_SourcingEventApiErrorCode_EventNotFound")}
      {t("zz_SourcingEventApiErrorCode_InvalidSourcingEventId")}
      {t("zz_SourcingEventApiErrorCode_InvitationAlreadyAccepted")}
      {t("zz_SourcingEventApiErrorCode_InvalidDepartmentId")}
      {t("zz_SourcingEventApiErrorCode_EventStateNotPublished")}
      {t("zz_SourcingEventApiErrorCode_InvalidStateForOneOrMoreBids")}
      {t("zz_SourcingEventApiErrorCode_CannotAwardBeforeDeadline")}
      {t("zz_SourcingEventApiErrorCode_CanOnlyDeleteSourcingEventsInDraftMode")}
      {t("zz_SourcingEventApiErrorCode_InvalidIdsForSelectedIndustries")}
      {t("zz_SourcingEventApiErrorCode_InvalidIdsForOrganizationsToInvite")}
      {t("zz_SourcingEventApiErrorCode_InvalidEmailsForPersonsToInvite")}
      {t("zz_SourcingEventApiErrorCode_InvalidDeadline")}
      {t("zz_SourcingEventApiErrorCode_CannotPerformEditInAwardedState")}
      {t("zz_SourcingEventApiErrorCode_CannotRemoveDescriptionInPublishedState")}
      {t("zz_SourcingEventApiErrorCode_CannotRemoveDeadlineInPublishedState")}
      {t("zz_SourcingEventApiErrorCode_InvalidDocumentId")}
      {t("zz_SourcingEventApiErrorCode_EmailInviteToAddAlreadyExists")}
      {t("zz_SourcingEventApiErrorCode_EmailInviteToBeRemovedNotFound")}
      {t("zz_SourcingEventApiErrorCode_OrganizationInviteNotFound")}
      {t("zz_SourcingEventApiErrorCode_OrganizationAlreadyInvited")}
      {t("zz_SourcingEventApiErrorCode_OrganizationIdsToInviteNotFound")}
      {t("zz_SourcingEventApiErrorCode_CannotEditEventValueInPublishedState")}
      {t("zz_SourcingEventApiErrorCode_CannotEditEventValueInDraftState")}
      {t("zz_SourcingEventApiErrorCode_InvalidBidIds")}
      {t("zz_SourcingEventApiErrorCode_InvalidOrganizationIdsInInvites")}
      {t("zz_SourcingEventApiErrorCode_CannotRemoveOrganizationThatHasDeliveredBid")}
      {t("zz_SourcingEventApiErrorCode_CanOnlyPublishEventsInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotAwardEventAlreadyAwarded")}
      {t("zz_SourcingEventApiErrorCode_CannotAcceptOrganizationInviteInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotAcceptPersonInviteInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotAddCommentToResolvedQuestion")}
      {t("zz_SourcingEventApiErrorCode_CannotAwardEventInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotCompleteBeforeDeadline")}
      {t("zz_SourcingEventApiErrorCode_CannotPublishWithoutDeadline")}
      {t("zz_SourcingEventApiErrorCode_CannotPublishWithoutDescription")}
      {t("zz_SourcingEventApiErrorCode_CannotPublishWithoutInvitedParties")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateAnnouncementsInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateEventValueInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateEventValueInPublishedState")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateQuestionsInAwardedState")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateQuestionsInDraftState")}
      {t("zz_SourcingEventApiErrorCode_OrganizationInviteAlreadyAccepted")}
      {t("zz_SourcingEventApiErrorCode_OrganizationNotInvited")}
      {t("zz_SourcingEventApiErrorCode_CannotAcceptInviteBecauseOrganizationIsNotInvited")}
      {t("zz_SourcingEventApiErrorCode_CannotAcceptEmailInviteBecauseItWasNotFound")}
      {t("zz_SourcingEventApiErrorCode_QuestionNotFound")}
      {t("zz_SourcingEventApiErrorCode_AlreadyInvitedToSourcingEvent")}
      {t("zz_SourcingEventApiErrorCode_CannotAddViewingInAwardedState")}
      {t("zz_SourcingEventApiErrorCode_CannotAddViewingInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CanOnlyAddViewingWhenOrganizationIsInvited")}
      {t("zz_SourcingEventApiErrorCode_CannotCompleteEventAlreadyCompleted")}
      {t("zz_SourcingEventApiErrorCode_CannotAddViewingInCompletedState")}
      {t("zz_SourcingEventApiErrorCode_CannotPerformEditInCompletedState")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateEventValueInCompletedState")}
      {t("zz_SourcingEventApiErrorCode_CannotUpdateQuestionsInCompletedState")}
      {t("zz_SourcingEventApiErrorCode_CannotSelectProductsInAwardedState")}
      {t("zz_SourcingEventApiErrorCode_CannotSelectProductsInCompletedState")}
      {t("zz_SourcingEventApiErrorCode_CannotSelectProductsInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotRemoveProductsInAwardedState")}
      {t("zz_SourcingEventApiErrorCode_CannotRemoveProductsInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotRemoveProductsInCompletedState")}
      {t("zz_SourcingEventApiErrorCode_CannotCompleteInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotOpenPublishedEvent")}
      {t("zz_SourcingEventApiErrorCode_CannotOpenDraftEvent")}
      {t("zz_SourcingEventApiErrorCode_CannotOpenAwardedEvent")}
      {t("zz_SourcingEventApiErrorCode_CannotAddContractInPublishedState")}
      {t("zz_SourcingEventApiErrorCode_CannotAddContractInDraftState")}
      {t("zz_SourcingEventApiErrorCode_CannotAddContractInCompletedState")}
      {t("zz_UploadApiErrorCode_Unknown")}
      {t("zz_UploadApiErrorCode_MimeTypeNotSupported")}
      {t("zz_UploadApiErrorCode_InvalidSourcingEventId")}
      {t("zz_UploadApiErrorCode_InvalidUploadId")}
      {t("zz_UploadApiErrorCode_InvalidBidId")}
      {t("zz_UploadApiErrorCode_CannotDeleteCompletedUpload")}
      {t("zz_UploadApiErrorCode_InvalidContractId")}
      {t("zz_UploadApiErrorCode_InvalidProjectId")}
    </Box>
  );
};
