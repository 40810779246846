import { Button, Flex, FormControl, FormHelperText, FormLabel, Icon, Input, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { CreateProjectRequest, ParentProjectDto, useCreateProjectMutation } from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { SingleSelector } from "../../common/input/Selector/SingleSelector";
import { TimestampSelector } from "../../common/input/TimestampSelector/TimestampSelector";
import { urls } from "../../urls";
import { displayPersonNameWithEmail } from "../contracts/view-single/sharing/AddParticipantModal";
import { ProjectSelector } from "./ProjectSelector";
import { useProjectTypes } from "./useProjectTypes";

const initializeProject = (): CreateProjectRequest => ({
  id: uuid(),
  externalId: "",
  name: "",
  startDate: undefined,
  endDate: undefined,
  parent: undefined,
  projectResponsible: "",
  sourcingResponsible: "",
});

export const CreateProjectForm = ({ setShowModal }: { setShowModal: Dispatch<SetStateAction<boolean>> }) => {
  const navigate = useNavigate();
  const users = useLoggedInWithOrgContextState().selectedOrg.users;
  const [createProject, { isLoading: isCreatingProject }] = useCreateProjectMutation();
  const projectTypes = useProjectTypes();
  const toast = useToast();

  const [newProject, setNewProject] = useState<CreateProjectRequest>(initializeProject());
  const [parentProject, setParentProject] = useState<ParentProjectDto>();

  const usersWithProjectAccess = useMemo(() => {
    return users.filter((u) =>
      u.roles.some((r) => r === "Owner" || r === "Admin" || r === "ProjectCreator" || r === "ProjectViewer")
    );
  }, [users]);

  const selectedSourcingResponsible = useMemo(() => {
    const user = usersWithProjectAccess.find((u) => u.person.id === newProject.sourcingResponsible);
    if (!newProject.sourcingResponsible || !user) return null;
    return { label: displayPersonNameWithEmail(user.person), value: user.person.id };
  }, [newProject.sourcingResponsible, usersWithProjectAccess]);

  const selectedProjectResponsible = useMemo(() => {
    const user = usersWithProjectAccess.find((u) => u.person.id === newProject.projectResponsible);
    if (!newProject.projectResponsible || !user) return null;
    return { label: displayPersonNameWithEmail(user.person), value: user.person.id };
  }, [newProject.projectResponsible, usersWithProjectAccess]);

  return (
    <>
      <Flex grow={1} flexDirection={"column"} overflowY={"scroll"} shadow="inner" p="1">
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Project ID")}</FormLabel>
          <Input
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            placeholder={`${t("Project ID")}...`}
            value={newProject.externalId}
            onChange={(e) => setNewProject((p) => ({ ...p, externalId: e.target.value }))}
          />
          <FormHelperText>{`${t("Enter your unique project identifier")} (${t("optional")}).`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Name")}</FormLabel>
          <Input
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            placeholder={`${t("Name")}...`}
            value={newProject.name}
            onChange={(e) => setNewProject((p) => ({ ...p, name: e.target.value }))}
          />
          <FormHelperText>{`${t("Enter a descriptive project name")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Project type")}</FormLabel>
          <SingleSelector
            size="sm"
            placeholder={{ text: `${t("Project type")}...`, color: "" }}
            value={projectTypes.find((t) => t.value === newProject.projectType) ?? null}
            options={projectTypes}
            onChange={(option) => setNewProject((p) => ({ ...p, projectType: option?.value }))}
          />
          <FormHelperText>{`${t("Select the project type")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Start date")}</FormLabel>
          <TimestampSelector
            datePlaceholderText={`${t("Select date")}...`}
            showTimeInput={false}
            date={newProject?.startDate ?? null}
            allowPastDates
            errorMessage={null}
            dateFormat={"dd.MM.yyyy"}
            onDateChange={(d) => setNewProject((p) => ({ ...p, startDate: d ?? undefined }))}
          />
          <FormHelperText>{`${t("Select the project start date")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("End date")}</FormLabel>
          <TimestampSelector
            datePlaceholderText={`${t("Select date")}...`}
            showTimeInput={false}
            date={newProject?.endDate ?? null}
            allowPastDates
            errorMessage={null}
            dateFormat={"dd.MM.yyyy"}
            onDateChange={(d) => setNewProject((p) => ({ ...p, endDate: d ?? undefined }))}
          />
          <FormHelperText>{`${t("Select the project end date")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Parent project")}</FormLabel>
          <ProjectSelector
            selectedProject={parentProject}
            onChange={(parent) => {
              setParentProject(parent);
              setNewProject((p) => ({ ...p, parent: parent?.id }));
            }}
          />
          <FormHelperText>{`${t("Select the parent project")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Project responsible")}</FormLabel>
          <SingleSelector
            size="sm"
            placeholder={{ text: `${t("Project responsible")}...`, color: "" }}
            value={selectedProjectResponsible}
            options={usersWithProjectAccess.map((u) => ({
              label: displayPersonNameWithEmail(u.person),
              value: u.person.id,
            }))}
            onChange={(option) => setNewProject((p) => ({ ...p, projectResponsible: option?.value }))}
          />
          <FormHelperText>{`${t("Select the person responsible for the project")}. ${t(
            "Only users with project access can be selected"
          )}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Sourcing responsible")}</FormLabel>
          <SingleSelector
            size="sm"
            placeholder={{ text: `${t("Sourcing responsible")}...`, color: "" }}
            value={selectedSourcingResponsible}
            options={usersWithProjectAccess.map((u) => ({
              label: displayPersonNameWithEmail(u.person),
              value: u.person.id,
            }))}
            onChange={(option) => setNewProject((p) => ({ ...p, sourcingResponsible: option?.value }))}
          />
          <FormHelperText>{`${t("Select the sourcing responsible for the project")}. ${t(
            "Only users with project access can be selected"
          )}.`}</FormHelperText>
        </FormControl>
      </Flex>
      <Flex>
        <Button
          size={"sm"}
          variant={"outline"}
          colorScheme="teal"
          leftIcon={<Icon as={FaCheck} />}
          isDisabled={!newProject.name}
          isLoading={isCreatingProject}
          onClick={async () => {
            const res = await createProject({
              createProjectRequest: {
                id: newProject.id ? newProject.id : undefined,
                externalId: newProject.externalId ? newProject.externalId : undefined,
                name: newProject.name,
                startDate: newProject.startDate ? newProject.startDate : undefined,
                endDate: newProject.endDate ? newProject.endDate : undefined,
                parent: newProject.parent ? newProject.parent : undefined,
                projectResponsible: newProject.projectResponsible ? newProject.projectResponsible : undefined,
                sourcingResponsible: newProject.sourcingResponsible ? newProject.sourcingResponsible : undefined,
                projectType: newProject.projectType ? newProject.projectType : undefined,
              },
            });
            if ("error" in res) {
              toast({ status: "error", title: t("Could not create project") ?? "" });
              return;
            }
            setNewProject(initializeProject());
            setShowModal(false);
            navigate(urls.projects.view.go(res.data.id));
          }}
        >
          {t("Save")}
        </Button>
      </Flex>
    </>
  );
};
