import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { FaNetworkWired, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CreateProjectModal } from "../../../../pages/projects/CreateProjectModal";
import { urls } from "../../../../urls";
import { useLoggedInWithOrgContextState } from "../../../auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../subscription/useSub";
import { Explanation } from "../../../support/Explanation";

export const Projects = () => {
  const navigate = useNavigate();
  const sub = useSub();
  const authState = useLoggedInWithOrgContextState();

  const canCreateProject = useMemo(() => {
    return authState.selectedOrg.canCreateProject;
  }, [authState.selectedOrg.canCreateProject]);

  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);

  return sub.hasProjectAccess ? (
    <>
      <CreateProjectModal isOpen={isCreateProjectModalOpen} setShowModal={setIsCreateProjectModalOpen} />
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<Icon as={FaNetworkWired} w="15px" h="15px" />}
          colorScheme="teal"
          variant="ghost"
          size={"sm"}
        >
          {t("Projects")}
        </MenuButton>
        <MenuList>
          <Explanation
            enabled={!canCreateProject}
            placement={"left-start"}
            text={t("Only admins and creators can create new projects")}
          >
            <MenuItem
              isDisabled={!canCreateProject}
              icon={<Icon as={FaPlus} w="15px" h="15px" marginBottom={-0.5} />}
              onClick={() => setIsCreateProjectModalOpen(true)}
            >
              {t("New project")}
            </MenuItem>
          </Explanation>
          <MenuItem
            icon={<Icon as={FaNetworkWired} w="15px" h="15px" marginBottom={-0.5} />}
            onClick={() => {
              navigate(urls.projects.viewAll);
            }}
          >
            {t("All projects")}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  ) : null;
};
