import { PlacementWithLogical, Tooltip } from "@chakra-ui/react";
import React from "react";

export interface Props {
  text: React.ReactNode;
  enabled: boolean;
  children: React.ReactNode;
  placement?: PlacementWithLogical;
  fontSize?: "sm" | "md";
  shouldWrapChildren?: boolean;
}

export const Explanation = ({ text, children, enabled, placement, fontSize = "sm", shouldWrapChildren }: Props) => {
  if (enabled) {
    return (
      <Tooltip
        label={text}
        fontSize={fontSize ?? "md"}
        placement={placement}
        shouldWrapChildren={shouldWrapChildren}
        rounded="md"
      >
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};
