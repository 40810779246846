import { Checkbox, Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ContractListItemDto, CounterpartyDto, CountryDto } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { displayDate } from "../../../common/formatting/displayDate";
import { Explanation } from "../../../common/support/Explanation";
import { urls } from "../../../urls";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { ContractStatusTag } from "../edit/ContractStatusTag";
import { ContractTemplateTag } from "../edit/ContractTemplateTag";
import { requireCounterparty, requireStatus } from "../typeguards";

interface Props {
  contract: ContractListItemDto;
  ifrsReportingEnabled: boolean;
  selectedForIfrsReporting: boolean;
  selectedForIfrsReportingChanged: (newValue: boolean) => void;
}

export const PublishedContractRow = ({
  contract,
  ifrsReportingEnabled,
  selectedForIfrsReporting,
  selectedForIfrsReportingChanged,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();

  const counterparty = requireCounterparty(contract);
  const name = getCounterpartyName(counterparty);

  const onClick = () => {
    navigate(urls.contracts.viewSingle.go(contract.id));
  };

  return (
    <Tr key={contract.id} _hover={{ bg: "smBackgroundSecondary" }} cursor={"pointer"}>
      <Td onClick={onClick}>
        <Text fontSize={"sm"}>{contract.title}</Text>
      </Td>
      <Td onClick={onClick}>
        <Flex>
          <Flex>
            <ProfileImageRounded name={name} backgroundColor={"blue.500"} width={"20px"} fontSize="xx-small" />
          </Flex>
          <Flex mt="-1px" ml="5px">
            <Text fontSize={"sm"}>{name}</Text>
          </Flex>
        </Flex>
      </Td>
      <Td onClick={onClick}>
        <Text fontSize={"sm"}>
          {contract.internalParties?.map((p, i, parties) => (
            <Fragment key={p.organizationId}>
              {p.organizationName}
              {i >= parties.length - 1 ? "" : ", "}
            </Fragment>
          ))}
        </Text>
      </Td>
      <Td onClick={onClick}>
        {contract.endDate ? (
          <Text fontSize={"sm"}>{displayDate(contract.endDate)}</Text>
        ) : contract.hasNoEndDate ? (
          <Text fontSize={"sm"}>{t("Has no end date")}</Text>
        ) : (
          <Text fontSize={"sm"} fontStyle={"italic"}>
            {t("Not provided")}
          </Text>
        )}
      </Td>
      <Td onClick={onClick}>
        <Flex>
          <Flex>
            <ProfileImageRounded
              name={contract.internalOwner.firstName}
              backgroundColor={"smPrimary"}
              width={"20px"}
              fontSize="xx-small"
            />
          </Flex>
          <Flex mt="-2px" ml="5px">
            <Text fontSize={"sm"}>{`${contract.internalOwner.firstName} ${contract.internalOwner.lastName}`}</Text>
          </Flex>
        </Flex>
      </Td>
      <Td onClick={onClick}>
        <ContractStatusTag status={requireStatus(contract)} />
      </Td>
      <Td onClick={onClick}>
        <Text fontSize={"sm"}>
          {contract.template ? <ContractTemplateTag template={contract.template} /> : <i>{t("Not provided")}</i>}
        </Text>
      </Td>
      <Td onClick={onClick}>
        {contract.iamParticipant && contract.owningOrganization.id !== authState.selectedOrg.id ? (
          <Explanation text={t(`${contract.owningOrganization.name} shared this contract with you`)} enabled={true}>
            <Text>{t("External")}</Text>
          </Explanation>
        ) : (
          <Explanation text={t("Your organization owns this contract")} enabled={true}>
            <Text>{t("Your org")}</Text>
          </Explanation>
        )}
      </Td>
      {ifrsReportingEnabled && (
        <Td>
          <Explanation
            enabled={!contract.containsIfrsValues}
            text={t("IFRS-values not added")}
            shouldWrapChildren={true}
          >
            <Checkbox
              bg="smBackground"
              ml="10px"
              size="lg"
              isChecked={selectedForIfrsReporting}
              isDisabled={!contract.containsIfrsValues}
              onChange={async () => {
                const newValue = !selectedForIfrsReporting;
                selectedForIfrsReportingChanged(newValue);
              }}
            />
          </Explanation>
        </Td>
      )}
    </Tr>
  );
};

export const getCounterpartyName = (counterparty: CounterpartyDto): string => {
  if (counterparty.organization) {
    return counterparty.organization.name;
  } else if (counterparty.organizationEntry) {
    return counterparty.organizationEntry.name;
  } else {
    throw Error("should never happen");
  }
};

export const getCounterpartyCountry = (counterparty: CounterpartyDto): CountryDto => {
  if (counterparty.organization) {
    return counterparty.organization.country;
  } else if (counterparty.organizationEntry) {
    return counterparty.organizationEntry.country;
  } else {
    throw Error("should never happen");
  }
};
