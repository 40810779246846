import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { Logo } from "../../common/Logo";
import { useSub } from "../../common/subscription/useSub";
import { CreateProjectForm } from "./CreateProjectForm";
import { CreateProjectWithAI } from "./CreateProjectWithAI";

type FormType = "manual" | "ai";

export const CreateProjectModal = ({
  isOpen,
  setShowModal,
}: {
  isOpen: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const sub = useSub();

  const [formType, setFormType] = useState<FormType>("manual");

  return (
    <Modal isOpen={isOpen} onClose={() => setShowModal(false)} size="3xl">
      <ModalOverlay />
      <ModalContent width="full" height={"80%"} maxHeight={"80%"} overflow={"hidden"}>
        <ModalCloseButton />
        <ModalBody height="full" pb={6}>
          <Flex height={"full"} flexDirection={"column"}>
            <Heading as="h2" size="md" pt="5" pb="4">
              {t("Create Project")}
            </Heading>
            {sub.hasAiAccess && (
              <Flex width="full" justifyContent={"center"} pt="5">
                <Flex>
                  <Button
                    roundedRight="none"
                    variant={formType === "manual" ? "solid" : "ghost"}
                    colorScheme={"teal"}
                    rounded={"md"}
                    border="1px solid teal"
                    onClick={() => setFormType("manual")}
                  >
                    {t("Manual")}
                  </Button>
                  <Button
                    roundedLeft="none"
                    variant={formType === "ai" ? "solid" : "ghost"}
                    overflow={"hidden"}
                    colorScheme={"teal"}
                    rounded={"md"}
                    border="1px solid teal"
                    borderLeft={"none"}
                    rightIcon={<Logo />}
                    onClick={() => setFormType("ai")}
                  >
                    {t("AI")}
                  </Button>
                </Flex>
              </Flex>
            )}
            {formType == "manual" && <CreateProjectForm setShowModal={setShowModal} />}
            {formType == "ai" && <CreateProjectWithAI setShowModal={setShowModal} />}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
