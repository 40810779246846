import { ProjectDto, useListEventsForBuyerQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { SourcingEventsTableForBuyer } from "../../sourcing-events/buyer/view-all/SourcingEventsTableForBuyer";

export const SourcingEvents = ({ project }: { project: ProjectDto }) => {
  const authState = useLoggedInWithOrgContextState();

  const { data, error, isLoading } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    status: "All",
    projectId: project.id,
  });
  return <SourcingEventsTableForBuyer status={"All"} events={data?.events} error={error} isLoading={isLoading} />;
};
