import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateBasicSourcingEventMutation } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { urls } from "../../../urls";

interface FormValues {
  title: string;
}

export const useCreateBasicSourcingEventModal = (): {
  showCreateBasicSourcingEventModal: () => void;
  createBasicSourcingEventModal: ReactElement;
} => {
  const [showModal, setShowModal] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [create, { isLoading }] = useCreateBasicSourcingEventMutation();
  const apiErrorDisplayer = useApiError();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<FormValues>();

  const submit = async (values: FormValues) => {
    const result = await create({
      depId: authState.selectedOrg.departments[0].id,
      createSourcingEventRequest: {
        title: values.title,
        timezone: moment.tz.guess(),
      },
    });

    if ("data" in result) {
      setValue("title", "");
      toast({
        title: t("New event draft created!"),
        status: "success",
        position: "top-right",
      });
      if (urls.events.edit.isCurrentPage()) {
        window.location.replace(urls.events.edit.go(result.data.id).intro.fullPathName());
      } else {
        setShowModal(false);
        navigate(urls.events.edit.go(result.data.id).intro, {
          replace: true,
        });
      }
    } else {
      apiErrorDisplayer.trigger(result.error);
    }
  };

  return {
    showCreateBasicSourcingEventModal: () => setShowModal(() => true),
    createBasicSourcingEventModal: (
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("New sourcing event draft")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(submit)}>
              <FormControl isInvalid={errors.title !== undefined} mt="10px">
                <FormLabel htmlFor={"title"}>{t("Event title")}</FormLabel>
                <Input
                  id={"title"}
                  type="title"
                  {...register("title", {
                    required: t("Please provide a title for your event draft") ?? "",
                    minLength: {
                      value: 2,
                      message: t("Must be at least 2 characters long"),
                    },
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
                <FormHelperText>{t("Please provide a title for your event draft")}</FormHelperText>
              </FormControl>
              <Flex mt="20px" justifyContent={"end"}>
                <Button type="submit" colorScheme="teal" isLoading={isLoading}>
                  {t("Create event")}
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
  };
};
