import { Box, Flex, Icon, IconButton, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCompressAlt, FaExpandAlt } from "react-icons/fa";
import { Product, ProductGroup } from "../../../../common/types";
import { ProductRowForm } from "./ProductRowForm";

export const ProductGroupForm = ({ group }: { group: ProductGroup }) => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  return (
    <Box
      width={"full"}
      maxWidth={"full"}
      left="0"
      backgroundColor="smBackgroundSecondary"
      border="1px solid teal"
      rounded={"lg"}
      position={showFullScreen ? "absolute" : "relative"}
      zIndex={showFullScreen ? "docked" : "auto"}
      p="5"
      mb="5"
    >
      <Flex alignItems={"center"} pb="4" pl="4" justifyContent={"space-between"}>
        <Text size="lg" fontWeight={"bold"}>
          {group.name}
        </Text>
        <IconButton
          aria-label="Toggle product group size"
          icon={<Icon as={showFullScreen ? FaCompressAlt : FaExpandAlt} />}
          size="sm"
          variant={"ghost"}
          onClick={() => setShowFullScreen(!showFullScreen)}
        />
      </Flex>
      <Box width={"full"} maxWidth={"full"} overflowX={"auto"} rounded={"lg"}>
        <Table variant={"simple"} size="sm">
          <Thead>
            <Tr>
              {group.productFields
                .filter((field) => field.populatedBy === "Buyer")
                .map((field) => (
                  <Th key={field.id}>{field.name}</Th>
                ))}
              <Th>{t("Quantity")}</Th>
              <Th>{t("Unit Price")}</Th>
              {group.productFields
                .filter((field) => field.populatedBy === "Supplier")
                .map((field) => (
                  <Th key={field.id}>{field.name}</Th>
                ))}
            </Tr>
          </Thead>
          <Tbody>
            {group.products?.map((product) => (
              <ProductRowForm
                key={product.id}
                groupId={group.id}
                product={product as Product}
                productFields={group.productFields}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
