import {
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import {
  GptMessage,
  SignedDocumentDto,
  useSendAiChatMessageMutation,
  useStartDocumentChatMutation,
  ViewDocumentAsOwnerDto,
} from "../../../../autogen/bff-api";
import { Logo } from "../../../../common/Logo";

export const DocumentAnalysisModal = ({
  document,
  setDocumentForAnalysis,
}: {
  document: ViewDocumentAsOwnerDto | SignedDocumentDto;
  setDocumentForAnalysis: Dispatch<SetStateAction<ViewDocumentAsOwnerDto | SignedDocumentDto | undefined>>;
}) => {
  const [startChat, { isLoading: isStartingChat }] = useStartDocumentChatMutation();
  const [sendMessage, { isLoading: isSendingMessage }] = useSendAiChatMessageMutation();

  const [newMessage, setNewMessage] = useState<string>("");
  const [messages, setMessages] = useState<GptMessage[]>();

  const chatRef = useRef<HTMLDivElement | null>(null);
  const msgRef = useRef<HTMLTextAreaElement | null>(null);
  const chatButtonRef = useRef<HTMLButtonElement | null>(null);

  const summaryPrompt = useMemo(() => t(`Generate a short summary of the given text`), []);

  const scrollToBottom = () => {
    if (chatRef.current) chatRef.current.scrollTop = chatRef.current.scrollHeight;
  };

  const handleClick = async (msg: string) => {
    if (!messages) await handleNewChat(msg);
    else await handleMessage(msg);
    setNewMessage("");
    msgRef.current?.focus();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClick(newMessage);
    }
  };

  const handleNewChat = async (msg: string) => {
    const updatedMessages = [{ role: "user", content: msg }];
    setMessages(updatedMessages);
    const response = await startChat({
      docId: document.id,
      chatCompletionRequest: {
        model: "gpt-4o",
        messages: updatedMessages,
        temperature: 0.7,
      },
    });
    if ("data" in response) setMessages(response.data.messages);
    else console.error("Failed to analyze document");
  };

  const handleMessage = async (msg: string) => {
    if (!messages?.length) throw Error("Unexpected empty chat");
    const updatedMessages = [...messages, { role: "user", content: msg }];
    setMessages(updatedMessages);
    const response = await sendMessage({
      chatCompletionRequest: {
        model: "gpt-4o",
        messages: updatedMessages,
        temperature: 0.7,
      },
    });
    const reply = "data" in response ? response.data.messages[0] : undefined;
    if (reply) setMessages((m) => [...(m ?? []), reply]);
    else throw Error("Failed to get AI response");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Modal size={"2xl"} isOpen={!!document} onClose={() => setDocumentForAnalysis(undefined)}>
      <ModalOverlay />
      <ModalContent maxHeight={"80%"} height={"80%"} p="5">
        <Flex height="full" flexDir={"column"} p={"4"} rounded="base" overflow={"hidden"}>
          <Flex alignItems={"center"} pb={"4"}>
            <Flex color="smPrimary" mr="1">
              <Logo />
            </Flex>
            {t("Chat regarding")}:
            <Text fontWeight={"bold"} pl="1">
              {document.name}
            </Text>
          </Flex>
          <Flex
            ref={chatRef}
            flexDirection="column"
            flexGrow="1"
            overflow={"auto"}
            width={"full"}
            bgColor="smBackgroundSecondary"
            rounded="lg"
            mb="2"
            p="1"
            justifyContent="space-between"
          >
            <Flex flexDirection="column">
              {messages
                ?.filter((m) => m.role !== "system")
                ?.map((m, i) => (
                  <Flex
                    key={i}
                    flexDirection="column"
                    alignItems={m.role === "user" ? "start" : "end"}
                    rowGap="1"
                    py="4"
                  >
                    {m.role !== "user" && (
                      <Flex color="smPrimary">
                        <Icon as={Logo} />
                      </Flex>
                    )}
                    <Text
                      whiteSpace={"pre-line"}
                      maxWidth="80%"
                      rounded="md"
                      backgroundColor="smBackgroundTertiary"
                      p="4"
                    >
                      {m.content}
                    </Text>
                  </Flex>
                ))}
            </Flex>
            {(isStartingChat || isSendingMessage) && <Spinner colorScheme="teal" minHeight="6" minWidth="6" />}
          </Flex>
          <Textarea
            ref={msgRef}
            autoFocus
            placeholder={`${t("Ask the AI something")}...`}
            name="new chat message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              handleKeyPress(e);
            }}
          />
          <Flex width={"full"} justifyContent="space-between" flexDirection="row-reverse" pt="2">
            <IconButton
              ref={chatButtonRef}
              aria-label="ask ai"
              icon={<Icon as={FaArrowUp} />}
              colorScheme="purple"
              isDisabled={!newMessage}
              onClick={() => handleClick(newMessage)}
            />
            <Button
              variant="outline"
              colorScheme="teal"
              onClick={() => {
                setNewMessage(summaryPrompt);
                handleClick(summaryPrompt);
              }}
            >
              {t("Generate Summary")}...
            </Button>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
