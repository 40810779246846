import { Flex, Grid, GridItem, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaCubes, FaFileSignature, FaFolder, FaInfoCircle, FaShoppingBasket } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../../autogen/bff-api";
import { EntityViewSkeleton } from "../../../common/EntityViewSkeleton";
import { isFetchBaseQueryError } from "../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { ProjectTab, useQueryParams } from "../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../config";
import { Contracts } from "./Contracts";
import { Documents } from "./Documents";
import { ProjectDetails } from "./ProjectDetails";
import { ProjectHeading } from "./ProjectHeading";
import { SourcingEvents } from "./SourcingEvents";

const tabs: ProjectTab[] = ["details", "documents", "products", "sourcing-events", "contracts"];
const tabToIndex = (tab: ProjectTab): number => tabs.indexOf(tab);
const indexToQueryValue = (index: number): ProjectTab => tabs[index];

export const ProjectPage = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("No project id");
  const [tabIndex, setTabIndex] = useState(0);
  const params = useQueryParams();

  const { data: project, error, isLoading, isFetching, refetch } = useGetProjectQuery({ projectId });
  const displayer = useApiError();

  useEffect(() => {
    const tab = params.getProjectTab();
    if (!tab) return;
    setTabIndex(tabToIndex(tab));
  }, [params]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    else return displayer.show(error);
  }

  return (
    <Layout>
      <Grid
        height="full"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem height="full" area={"content"}>
          <Grid
            width="full"
            gridTemplateColumns={"3fr 4fr 240px"}
            gridTemplateRows="minmax(50px,1fr) auto auto auto auto minmax(0,1fr)"
            templateAreas={`
                        "title title edit"
                        "createdMeta createdMeta edit"
                        "deadline deadline deadline"
                        "type type type"
                        "content content content"
                        `}
          >
            {(isLoading || (isFetching && project.id !== projectId)) && <EntityViewSkeleton />}
            {project && project.id === projectId && (
              <>
                <ProjectHeading project={project} refetch={refetch} />
                <GridItem area="content" display="flex" flexDirection="column" pt="6">
                  <Tabs
                    index={tabIndex}
                    onChange={(index) => {
                      setTabIndex(index);
                      params.setSelectedProjectTab(indexToQueryValue(index));
                    }}
                  >
                    <TabList>
                      <Tab>
                        <Icon as={FaInfoCircle} w="15px" h="15px" mr="2.5" />
                        {t("Details")}
                      </Tab>
                      <Tab>
                        <Icon as={FaFolder} w="15px" h="15px" mr="10px" />
                        {t("Documents")}
                      </Tab>
                      <Tab>
                        <Icon as={FaCubes} w="15px" h="15px" mr="10px" />
                        {t("Products")}
                      </Tab>
                      <Tab>
                        <Icon as={FaShoppingBasket} w="15px" h="15px" mr="10px" />
                        {t("Sourcing Events")}
                      </Tab>
                      <Tab>
                        <Icon as={FaFileSignature} w="15px" h="15px" mr="10px" />
                        {t("Contracts")}
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel p="5">
                        <ProjectDetails project={project} refetch={refetch} />
                      </TabPanel>
                      <TabPanel>
                        <Documents project={project} refetch={refetch} />
                      </TabPanel>
                      <TabPanel>
                        <Flex width="full" justifyContent="center" pt="5">
                          <Text color="smSecondary">{t("Under implementation")}...</Text>
                        </Flex>
                      </TabPanel>
                      <TabPanel>
                        <SourcingEvents project={project} />
                      </TabPanel>
                      <TabPanel>
                        <Contracts project={project} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
