import { ProjectDto, useListContractsQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { PublishedContractsTable } from "../../contracts/view-all-published/PublishedContractsTable";

export const Contracts = ({ project }: { project: ProjectDto }) => {
  const authState = useLoggedInWithOrgContextState();

  const { data, isLoading } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    projects: project.id,
  });

  return <PublishedContractsTable contracts={data?.contracts ?? []} isLoading={isLoading} limit={100} skip={0} />;
};
