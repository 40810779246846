import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  LinkBox,
  LinkOverlay,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { debounce, isFinite, isNil, isUndefined, parseInt } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChartLine, FaDownload } from "react-icons/fa";
import { ContractDto, Currency, IfrsValuesDto } from "../../../../../../autogen/bff-api";
import { TimestampSelector } from "../../../../../../common/input/TimestampSelector/TimestampSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { Explanation } from "../../../../../../common/support/Explanation";
import { requireBooleanEnvVar, requireStringEnvVar } from "../../../../../../config";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";
import { IfrsAdjustmentsModal } from "./ifrs-adjustments/IfrsAdjustmentsModal";

const subtitle = (name: string): React.ReactElement => {
  return (
    <Text color="#728197" fontSize={"sm"}>
      {name}
    </Text>
  );
};

const formatMonetaryAmount = ({ val, currency }: { val?: number; currency?: Currency }): string => {
  const formatted = `${currency} ` + (val?.toLocaleString() ?? "");
  return formatted;
};

const parseMonetaryAmount = (val: string, currency?: Currency): number => {
  if (!currency) throw Error("Failed to parse monetary amount.");
  const parsed = parseInt(val.replace(`${currency}`, ""));
  return parsed;
};

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const IfrsValues: React.FC<Props> = ({ removeDataField, isRequiredButNotProvided }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contractState = useContractState();
  const errorMessage = containsError(contractState, "EditPaymentTermsInDays") ? t("Update failed") : null;

  const [ifrsValues, setIfrsValues] = useState<IfrsValuesDto>();
  const [interestRate, setInterestRate] = useState<string>();
  const [currency, setCurrency] = useState<Currency>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasDeviatingFirstPeriod, setHasDeviatingFirstPeriod] = useState<boolean>(false);
  const [canDownloadReport, setCanDownloadReport] = useState(false);
  const [showAddAdjustment, setShowAddAdjustment] = useState(false);

  useEffect(() => {
    if (!ifrsValues) setIfrsValues(contractState.contract.dataFields?.ifrsValues);
  }, [contractState.contract.dataFields?.ifrsValues, ifrsValues]);

  useEffect(() => {
    if (!currency) setCurrency(contractState.contract.currency);
  }, [contractState.contract.currency, currency]);

  useEffect(() => {
    if (isUndefined(interestRate)) {
      const ir = contractState.contract.dataFields?.ifrsValues?.interestRate;
      setInterestRate((interestRate) =>
        interestRate ? interestRate : `${!isNil(ir) && isFinite(ir) ? ir * 100 : ""}`
      );
    }
  }, [contractState.contract.dataFields?.ifrsValues?.interestRate, interestRate]);

  useEffect(() => {
    if (ifrsValues?.startDateSecondPeriod) setHasDeviatingFirstPeriod(true);
  }, [ifrsValues?.startDateSecondPeriod]);

  useEffect(() => {
    const adjustments = contractState.contract.dataFields?.ifrsValues?.adjustments;
    if (adjustments) {
      setIfrsValues((ifrsValues) => (ifrsValues ? { ...ifrsValues, adjustments } : undefined));
    }
  }, [contractState.contract.dataFields?.ifrsValues?.adjustments]);

  const editIfrsValues = useCallback(
    async (value: IfrsValuesDto) => {
      setIsLoading(true);
      await dispatch(
        editContractThunk({
          command: {
            type: "EditIfrsValues",
            value,
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editIfrsValuesDebounced = useCallback(
    debounce((value: IfrsValuesDto) => editIfrsValues(value), 300),
    [editIfrsValues]
  );

  const handleIfrsValueChange = (value: IfrsValuesDto) => {
    setIfrsValues(value);
    editIfrsValuesDebounced(value);
  };

  useEffect(() => {
    if (
      ifrsValues?.startDate &&
      ifrsValues?.monthlyPayment &&
      ifrsValues?.paymentIntervalInMonths &&
      ifrsValues?.contractLengthInMonths &&
      ifrsValues?.interestRate
    ) {
      setCanDownloadReport(true);
    } else setCanDownloadReport(false);
  }, [ifrsValues]);

  if (!ifrsValues) return <p>{t("Loading")}...</p>;

  return (
    <>
      {showAddAdjustment && (
        <IfrsAdjustmentsModal
          contract={contractState.contract as ContractDto}
          onClose={() => setShowAddAdjustment(false)}
        />
      )}
      <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
        <Flex justifyContent={"space-between"}>
          <FormLabel htmlFor={"value"}>
            {t("IFRS reporting")} {isLoading && <Spinner size="xs" />}
          </FormLabel>
          <RemoveDataFieldButton removeDataField={removeDataField} />
        </Flex>
        <Flex w="100%">
          <Box borderRadius={"10px"} w="100%">
            <Box mt="1" mb="1">
              {subtitle(t("Start date") ?? "")}
            </Box>
            <TimestampSelector
              datePlaceholderText={t("Start date") ?? ""}
              date={ifrsValues?.startDate ?? null}
              showTimeInput={false}
              errorMessage={errorMessage}
              dateFormat={"dd MMM yyyy"}
              allowPastDates={true}
              onDateChange={async (startDate) => {
                handleIfrsValueChange({ ...ifrsValues, startDate: startDate ? startDate : undefined });
              }}
            />
            {requireBooleanEnvVar("VITE_ENABLE_DEVIATING_FIRST_IFRS_PERIOD") && (
              <Flex width={"full"}>
                <Box width={"50%"}>
                  <Box my="1">{subtitle(t("Deviating first period"))}</Box>
                  <Checkbox
                    colorScheme="teal"
                    backgroundColor="smBackground"
                    isChecked={hasDeviatingFirstPeriod}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setHasDeviatingFirstPeriod(isChecked);
                      handleIfrsValueChange({
                        ...ifrsValues,
                        startDateSecondPeriod: isChecked ? ifrsValues.startDateSecondPeriod : undefined,
                      });
                    }}
                    aria-label="deviating first period"
                    mx="4"
                    my="2"
                  />
                </Box>

                <Box width={"50%"}>
                  <Box mt="1" mb="1">
                    {subtitle(t("Second period start date"))}
                  </Box>
                  <TimestampSelector
                    isDisabled={!hasDeviatingFirstPeriod}
                    datePlaceholderText={t("Second period start date")}
                    date={ifrsValues?.startDateSecondPeriod ?? null}
                    showTimeInput={false}
                    errorMessage={errorMessage}
                    dateFormat={"dd MMM yyyy"}
                    allowPastDates={true}
                    onDateChange={async (startDateSecondPeriod) => {
                      handleIfrsValueChange({
                        ...ifrsValues,
                        startDateSecondPeriod: startDateSecondPeriod ? startDateSecondPeriod : undefined,
                      });
                    }}
                  />
                </Box>
              </Flex>
            )}
            <Box mt="5px">{subtitle(t("Monthly payment") ?? "")}</Box>
            <NumberInput
              mt="5px"
              w="100%"
              placeholder={t("Monthly payment") ?? ""}
              backgroundColor="smBackground"
              // Needed since NumberInput sometimes converts the custom value to Number.MAX_SAFE_INTEGER
              clampValueOnBlur={false}
              value={formatMonetaryAmount({ val: ifrsValues?.monthlyPayment, currency })}
              onChange={(amount) => {
                const monthlyPayment = parseMonetaryAmount(amount, currency);
                if (monthlyPayment < 0) {
                  console.log(monthlyPayment);
                  return;
                }
                handleIfrsValueChange({
                  ...ifrsValues,
                  monthlyPayment: !isNil(monthlyPayment) && !isNaN(monthlyPayment) ? monthlyPayment : undefined,
                });
              }}
            >
              <NumberInputField />
            </NumberInput>
            <Box mt="5px">{subtitle(t("Payment interval (months)"))}</Box>
            <Input
              mt="5px"
              type="number"
              placeholder={t("Payment interval (months)") ?? ""}
              backgroundColor="smBackground"
              value={ifrsValues?.paymentIntervalInMonths ?? ""}
              min={0}
              onChange={(e) => {
                const val = e.target.value.trim();
                const paymentIntervalInMonths = val ? parseInt(e.target.value.trim()) : undefined;
                handleIfrsValueChange({ ...ifrsValues, paymentIntervalInMonths });
              }}
            />
            <Box mt="5px">{subtitle(t("Contract length (months)") ?? "")}</Box>
            <Input
              mt="5px"
              type="number"
              placeholder={t("Contract length (months)") ?? ""}
              backgroundColor="smBackground"
              value={ifrsValues?.contractLengthInMonths ?? ""}
              min={0}
              onChange={(e) => {
                const val = e.target.value.trim();
                const contractLengthInMonths = val ? parseInt(val) : undefined;
                handleIfrsValueChange({ ...ifrsValues, contractLengthInMonths });
              }}
            />
            <Box mt="5px">{subtitle(t("Interest rate (%)") ?? "")}</Box>
            <NumberInput
              mt="5px"
              w="100%"
              backgroundColor="smBackground"
              // Needed since NumberInput sometimes converts the custom value to Number.MAX_SAFE_INTEGER
              clampValueOnBlur={false}
              value={interestRate ?? ""}
              onChange={(ir) => {
                setInterestRate(ir);
                const parsedInterestRate = ir === "" ? undefined : parseFloat(ir) / 100;
                handleIfrsValueChange({ ...ifrsValues, interestRate: parsedInterestRate });
              }}
            >
              <NumberInputField />
            </NumberInput>
          </Box>
        </Flex>
        <FormHelperText>{t("The values are used for IFRS reporting")}</FormHelperText>
        <Explanation
          fontSize="sm"
          enabled={!(ifrsValues?.filled === true)}
          text={t("Please fill out all other fields before adding adjustments")}
        >
          <Button
            mt="10px"
            size="sm"
            leftIcon={<Icon as={FaChartLine} w="15px" h="15px" />}
            variant={"solid"}
            colorScheme="green"
            onClick={() => setShowAddAdjustment(true)}
            isDisabled={!(ifrsValues?.filled === true)}
          >
            {t("Adjustments")}{" "}
            {ifrsValues?.adjustments && ifrsValues?.adjustments.length > 0 && `(${ifrsValues?.adjustments.length})`}
          </Button>
        </Explanation>
        <LinkBox mt="7px" width={0}>
          <LinkOverlay
            href={`${requireStringEnvVar("VITE_BFF_BASE_URL")}/api/contracts/${contractState.contract.id}/ifrs-report`}
          >
            <Explanation
              fontSize="sm"
              text={t("Please fill out all values before downloading the report")}
              enabled={!canDownloadReport}
            >
              <Button
                size="sm"
                leftIcon={<Icon as={FaDownload} w="15px" h="15px" />}
                isDisabled={!canDownloadReport}
                variant={"solid"}
                colorScheme="blue"
              >
                {t("Download IFRS report")}
              </Button>
            </Explanation>
          </LinkOverlay>
        </LinkBox>
        <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
      </FormControl>
    </>
  );
};
